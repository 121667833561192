import * as L from 'leaflet';

import {
  EModuleIDs,
  EModuleURLs,
  EOrderDefaultLoadOption,
  EColours,
  EOrderImportOptions,
  EVehicleImportOptions,
  EDriverImportOptions,
  EOrderStatuses,
  EOrderTypes,
  EReportTypes,
  EUserPermissions,
  EDatePresets,
  ECustomIcons,
  ETextClasses,
  EManifestStatuses,
  EMapTypes,
  ESafetyInspectionFrequency,
  ESafetyInspectionStatus,
  EVehicleInspectionStatus,
  ELicenseValidationStatus,
  ELicenseExpirationStatus,
  EImportStatus,
} from 'app/consts/enums';
import {
  IAppConfig,
  IManifestStatusInfo,
  IModuleRoute,
  IOrdersGetParameters,
  IRouteAutoallocateOptimizationOptions,
  IStatusInfo,
} from 'app/models/interfaces';

export const MAIN_ROUTE = 'app';
export const ONBOARDING_ROUTE = 'welcome';
export const LOGIN_ROUTE = 'login';
export const ROUTES: Record<IModuleRoute, EModuleURLs> = {
  [EModuleIDs.Dashboard]: EModuleURLs.Dashboard,
  [EModuleIDs.LiveMap]: EModuleURLs.LiveMap,
  [EModuleIDs.Orders]: EModuleURLs.Orders,
  [EModuleIDs.Manifests]: EModuleURLs.Manifests,
  [EModuleIDs.Vehicles]: EModuleURLs.Vehicles,
  [EModuleIDs.Customers]: EModuleURLs.Customers,
  [EModuleIDs.Drivers]: EModuleURLs.Drivers,
  [EModuleIDs.Timetables]: EModuleURLs.Timetables,
  [EModuleIDs.Zones]: EModuleURLs.Zones,
  [EModuleIDs.Accounts]: EModuleURLs.Accounts,
  [EModuleIDs.Users]: EModuleURLs.Users,
  [EModuleIDs.Reports]: EModuleURLs.Reports,
  [EModuleIDs.Settings]: EModuleURLs.Settings,
  [EModuleIDs.SafetyReport]: EModuleURLs.SafetyReport,
  [EModuleIDs.SafetyPlans]: EModuleURLs.SafetyPlans,
  [EModuleIDs.SafetySettings]: EModuleURLs.SafetySettings,
  [EModuleIDs.Analytics]: EModuleURLs.Analytics,
  [EModuleIDs.ManagementDashboard]: EModuleURLs.ManagementDashboard,
  [EModuleIDs.AllFeedback]: EModuleURLs.AllFeedback,
  [EModuleIDs.ManageQrCodes]: EModuleURLs.ManageQrCodes,
  [EModuleIDs.WorkspacePlans]: EModuleURLs.WorkspacePlans,
};

export const NEW_ID = 'new';

export const DEFAULT_APP_CONFIG: Partial<IAppConfig> = {
  defaultPickupTolerance: 60,
  defaultDeliveryTolerance: 60,
  defaultPickupServiceTime: 10,
  defaultDeliveryServiceTime: 10,
};

/** non-breaking space */
export const NBSP = ' ';

export const MANIFEST_STATUSES_MAP: Record<EManifestStatuses, IManifestStatusInfo> = {
  [EManifestStatuses.Pending]: {
    icon: ECustomIcons.Pause,
    color: ETextClasses.Blue,
    text: 'Pending',
  },
  [EManifestStatuses.InProgress]: {
    icon: ECustomIcons.Play,
    color: ETextClasses.Orange,
    text: 'In progress',
  },
  [EManifestStatuses.Completed]: {
    icon: ECustomIcons.Check,
    color: ETextClasses.Green,
    text: 'Completed',
  },
  [EManifestStatuses.Cancelled]: {
    icon: ECustomIcons.Cancel,
    color: ETextClasses.Red,
    text: 'Cancelled',
  },
};

export const CONTACT_PAGE = 'https://solbox.it/contact';
export const DEFAULT_PHOTO_LINK = 'assets/images/avatars/your_logo_here.png';
export const DEFAULT_LOGO_LINK = 'assets/images/logos/placeholder-company.png';

export const DEFAULT_COOKIE_PARAMS: [
  number, string, string, boolean, 'None'
] = [365, '/', null, true, 'None'];

export const DATE_PRESET_NAMES: Record<EDatePresets, string> = {
  [EDatePresets.Today]: 'Today',
  [EDatePresets.Yesterday]: 'Yesterday',
  [EDatePresets.Tomorrow]: 'Tomorrow',
  [EDatePresets.ThisWeek]: 'This week',
  [EDatePresets.LastWeek]: 'Last week',
  [EDatePresets.LastSevenDays]: 'Last 7 days',
  [EDatePresets.LastThirtyDays]: 'Last 30 days',
  [EDatePresets.ThisMonth]: 'This month',
  [EDatePresets.PreviousMonth]: 'Previous month',
};

export const CUSTOM_ICONS: Record<ECustomIcons, string> = {
  [ECustomIcons.Xls]: 'assets/icons/file-excel-regular.svg',
  [ECustomIcons.Pdf]: 'assets/icons/file-pdf-regular.svg',
  [ECustomIcons.Pause]: 'assets/icons/pause-filled.svg',
  [ECustomIcons.Play]: 'assets/icons/play-filled.svg',
  [ECustomIcons.Check]: 'assets/icons/check-filled.svg',
  [ECustomIcons.Cancel]: 'assets/icons/cancel-filled.svg',
  [ECustomIcons.TruckDelivery]: 'assets/icons/truck-delivery-filled.svg',
  [ECustomIcons.TruckPickup]: 'assets/icons/truck-pickup-filled.svg',
  [ECustomIcons.Link]: 'assets/icons/link.svg',
  [ECustomIcons.Pickup]: 'assets/icons/pickup.svg',
  [ECustomIcons.Delivery]: 'assets/icons/delivery.svg',
  [ECustomIcons.PickupDelivery]: 'assets/icons/pickup-delivery.svg',
  [ECustomIcons.PickupDeliveryDeliveryNode]: 'assets/icons/pickup-delivery-d-node.svg',
  [ECustomIcons.PickupDeliveryPickupNode]: 'assets/icons/pickup-delivery-p-node.svg',
  [ECustomIcons.Service]: 'assets/icons/service.svg',
  [ECustomIcons.Truck]: 'assets/icons/truck.svg',
  [ECustomIcons.Distance]: 'assets/icons/distance.svg',
  [ECustomIcons.Depot]: 'assets/icons/map-depot.svg',
  [ECustomIcons.Image]: 'assets/icons/image.svg',
  [ECustomIcons.QR]: 'assets/icons/qr-code.svg',
  [ECustomIcons.PieChart]: 'assets/icons/pie-chart.svg',
  [ECustomIcons.Device]: 'assets/icons/device.svg',
  [ECustomIcons.WarningCircleFilled]: 'assets/icons/warning-circle-filled.svg',
  [ECustomIcons.WarningCircle]: 'assets/icons/warning-circle.svg',
  [ECustomIcons.FailureCircleFilled]: 'assets/icons/failure-circle-filled.svg',
  [ECustomIcons.FailureCircle]: 'assets/icons/failure-circle.svg',
  [ECustomIcons.HandPalm]: 'assets/icons/hand-palm.svg',
  [ECustomIcons.Pickup2]: 'assets/icons/pickup-2.svg',
  [ECustomIcons.Delivery2]: 'assets/icons/delivery-2.svg',
  [ECustomIcons.PickupDelivery2]: 'assets/icons/pickup-delivery-2.svg',
  [ECustomIcons.PickupDeliveryPickup2]: 'assets/icons/pickup-delivery-pickup2.svg',
  [ECustomIcons.PickupDeliveryDelivery2]: 'assets/icons/pickup-delivery-delivery2.svg',
  [ECustomIcons.Service2]: 'assets/icons/service-2.svg',
  [ECustomIcons.Service3]: 'assets/icons/service-3.svg',
  [ECustomIcons.WarningRounded]: 'assets/icons/warning-rounded.svg',
  [ECustomIcons.ArrowUpCircle]: 'assets/icons/arrow-up-circle.svg',
};

export const ORDER_STATUS_ICON_MAP: Record<EOrderStatuses, ECustomIcons> = {
  [EOrderStatuses.Pending]: ECustomIcons.Pause,
  [EOrderStatuses.EnrouteForDelivery]: ECustomIcons.TruckDelivery,
  [EOrderStatuses.Delivered]: ECustomIcons.Check,
  [EOrderStatuses.EnrouteForPickup]: ECustomIcons.TruckPickup,
  [EOrderStatuses.PickedUp]: ECustomIcons.Check,
  [EOrderStatuses.Cancelled]: ECustomIcons.Cancel,
};

export const ORDER_STATUS_COLOUR_MAP: Record<EOrderStatuses, ETextClasses> = {
  [EOrderStatuses.Pending]: ETextClasses.Blue,
  [EOrderStatuses.EnrouteForDelivery]: ETextClasses.Orange,
  [EOrderStatuses.Delivered]: ETextClasses.Green,
  [EOrderStatuses.EnrouteForPickup]: ETextClasses.Orange,
  [EOrderStatuses.PickedUp]: ETextClasses.Green,
  [EOrderStatuses.Cancelled]: ETextClasses.Red,
};

export const ORDER_TYPE_ICON_MAP: Record<EOrderTypes, ECustomIcons> = {
  [EOrderTypes.Pickup]: ECustomIcons.Pickup,
  [EOrderTypes.Delivery]: ECustomIcons.Delivery,
  [EOrderTypes.PickupDelivery]: ECustomIcons.PickupDelivery,
  [EOrderTypes.Service]: ECustomIcons.Service,
};

export const ORDER_TYPE_ICON_LIVE_MAP: Record<EOrderTypes, ECustomIcons> = {
  [EOrderTypes.Pickup]: ECustomIcons.Pickup2,
  [EOrderTypes.Delivery]: ECustomIcons.Delivery2,
  [EOrderTypes.PickupDelivery]: ECustomIcons.PickupDelivery2,
  [EOrderTypes.Service]: ECustomIcons.Service2,
};

export const COLOUR_CLASS_COLOUR_MAP: Record<ETextClasses, EColours> = {
  [ETextClasses.Grey]: EColours.Grey,
  [ETextClasses.Green]: EColours.Green,
  [ETextClasses.Orange]: EColours.Orange,
  [ETextClasses.Red]: EColours.Red,
  [ETextClasses.Violet]: EColours.Violet,
  [ETextClasses.LavenderBlue]: EColours.LavenderBlue,
  [ETextClasses.Blue]: EColours.Blue,
  [ETextClasses.Black]: EColours.Black,
};
//#region Maps

export const AUTOCOMPLETE_OPTIONS: google.maps.places.AutocompleteOptions = {
  fields: ['formatted_address', 'geometry'],
};

export const DEFAULT_COUNTRY_CODE = 'au';

export const DEFAULT_ICON = L.icon({
  iconRetinaUrl: 'assets/icons/map-pin-2x.png',
  iconUrl: 'assets/icons/map-pin.png',
  iconSize: [30, 38],
  iconAnchor: [15, 38],
});

export const ORDER_ICON = (iconName: ECustomIcons): L.DivIcon => {
  return L.divIcon({
    html: `<div class="dashboard-icon map order ${iconName}"><img src="${CUSTOM_ICONS[iconName]}"></div>`,
    className: 'none',
    iconSize: [28, 28],
    iconAnchor: [14, 14],
  });
};

export const VEHICLE_ICON = (active = true): L.DivIcon => {
  const inactiveClass = (!active
    ? ' inactive'
    : ''
  );
  return L.divIcon({
    html: `<div class="dashboard-icon map vehicle${inactiveClass}"><img src="${CUSTOM_ICONS[ECustomIcons.Truck]}"></div>`,
    className: 'none',
    iconSize: [28, 28],
    iconAnchor: [14, 14],
  });
};

export const DEPOT_ICON = (): L.DivIcon => {
  return L.divIcon({
    html: `<div><img src="${CUSTOM_ICONS[ECustomIcons.Depot]}"></div>`,
    className: 'none',
    iconSize: [30, 38],
    iconAnchor: [15, 38],
  });
};

export const DEPOT_MARKER = (): L.Marker => {
  return L.marker(null, { icon: DEPOT_ICON() });
};

export const DOT_ICON = (
  textColor: EColours | '' = EColours.Grey,
  text: string | number = '',
  thickBorder = true,
): L.DivIcon => {
  return L.divIcon({
    html: `<div class="dashboard-dot${thickBorder ? ' thick-border' : ''}" style="color:${textColor}"><span>${text}</span></div>`,
    className: 'none',
    iconSize: [18, 18],
    iconAnchor: [9, 9],
  });
};

export const DASHBOARD_DOT_MARKER = (
  position: L.LatLng,
  paneName: string,
  textColor: ETextClasses | '' = ETextClasses.Grey,
  text: string | number = '',
): L.Marker => {
  return L.marker(position, {
    icon: DOT_ICON(COLOUR_CLASS_COLOUR_MAP[textColor], text, false),
    pane: paneName,
    riseOnHover: true,
    zIndexOffset: 3000,
  });
};

export const MAP_COLOURS = [
  EColours.Orchid,
  EColours.Carrot,
  EColours.VioletBlue,
  EColours.Pink,
  EColours.Emerald,
  EColours.ElectricBlue,
  EColours.Terracotta,
  EColours.Teal,
  EColours.Olive,
  EColours.Brown,
];

export const MAP_COLOUR_BY_INDEX = (index: number): EColours => {
  return MAP_COLOURS[index % MAP_COLOURS.length];
};

export const DEFAULT_MAP_ZOOM = 15;
export const CITY_MAP_ZOOM = 13;

export const MAP_LAYER_ICONS: Record<EMapTypes, string> = {
  [EMapTypes.Default]: 'assets/icons/light-map-button.png',
  [EMapTypes.Hybrid]: 'assets/icons/hybrid-map-button.png',
  [EMapTypes.Traffic]: 'assets/icons/traffic-map-button.png',
};

//#endregion Maps

//#region Orders
export const DEFAULT_ORDER_GET_PARAMETERS: IOrdersGetParameters = {
  //#region Status
  loadPendingOrders: true,
  loadDeliveringOrders: true,
  loadDeliveredOrders: true,
  loadCancelledOrders: true,
  //#endregion Status

  //#region Types
  loadDeliveryOrders: true,
  loadPickupOrders: true,
  loadServiceOrders: true,
  //#endregion Types

  //#region Other
  excludeManifestOrders: false,
  onlyShowTodaysOrders: false,
  loadOrdersBetweenDates: false,
  loadOrdersStartDate: null,
  loadOrdersEndDate: null,
  //  loadOrdersModifiedAfter: null,
  //#endregion Other
};

export const IMPORT_BUTTONS_NAMES: Record<EOrderImportOptions, string> = {
  [EOrderImportOptions.JIWA]: 'Import from JIWA',
  [EOrderImportOptions.MYOB]: 'Import from MYOB',
  [EOrderImportOptions.Shopify]: 'Import from Shopify',
  [EOrderImportOptions.Woodwards]: 'Woodwards import',
  [EOrderImportOptions.Accredo]: 'Accredo import',
  [EOrderImportOptions.WooCommerce]: 'Import from WooCommerce',
  [EOrderImportOptions.OrderMentum]: 'Import from OrderMentum',
  [EOrderImportOptions.Dynamics]: 'Import from Dynamics',
  [EOrderImportOptions.Fresho]: 'Import from Fresho',
};

export const IMPORT_VEHICLE_BUTTONS_NAMES: Record<EVehicleImportOptions, string> = {
  [EVehicleImportOptions.MiX]: 'Import from MiX Telematics',
};

export const IMPORT_DRIVER_BUTTONS_NAMES: Record<EDriverImportOptions, string> = {
  [EDriverImportOptions.MiX]: 'Import from MiX Telematics',
};

export const IMPORT_DIALOG_TITLES: Record<EOrderImportOptions, string> = {
  [EOrderImportOptions.JIWA]: 'JIWA import, select dates',
  [EOrderImportOptions.MYOB]: 'MYOB import, select company and dates',
  [EOrderImportOptions.Shopify]: 'Shopify orders import, select dates',
  [EOrderImportOptions.Woodwards]: 'Woodward DB orders import, select dates',
  [EOrderImportOptions.Accredo]: 'Accredo orders import, select dates',
  [EOrderImportOptions.WooCommerce]: '-',
  [EOrderImportOptions.OrderMentum]: 'Import Orders from OrderMentum',
  [EOrderImportOptions.Dynamics]: 'Dynamics DB orders import, select dates',
  [EOrderImportOptions.Fresho]: 'Fresho import',
};

export const IMPORT_VEHICLE_DIALOG_TITLES: Record<EVehicleImportOptions, string> = {
  [EVehicleImportOptions.MiX]: 'MiX Telematics vehicle import',
};

export const IMPORT_DRIVER_DIALOG_TITLES: Record<EDriverImportOptions, string> = {
  [EDriverImportOptions.MiX]: 'MiX Telematics driver import',
};

export const DEFAULT_ROUTE_OPTIMIZATION_OPTIONS: IRouteAutoallocateOptimizationOptions = {
  allow_wait_time: 40,
  export_to_xls: true,
  ignore_order_times: false,
  max_calc_time: 300,
  max_vehicle_time: 60000,
};
//#endregion Maps

//#region Reports
export const REPORT_TYPES: Record<
  EReportTypes, { code: string, displayValue: string, }
> = {
  [EReportTypes.DeliveredItems]: {
    code: 'delivered_items.xlsx',
    displayValue: 'Delivered Items',
  },
  [EReportTypes.DeliveredOrders]: {
    code: 'delivered_orders.xlsx',
    displayValue: 'Delivered Orders',
  },
  [EReportTypes.AllOrders]: {
    code: 'orders_report.xlsx',
    displayValue: 'All Orders',
  },
  [EReportTypes.ManifestItems]: {
    code: 'manifest_items.xlsx',
    displayValue: 'Grouped Manifest Item Summary',
  },
  [EReportTypes.TareTracking]: {
    code: 'tare_tracking.xlsx',
    displayValue: 'Tare tracking',
  },
  [EReportTypes.Label]: {
    code: 'label_report.xlsx',
    displayValue: 'Label'
  },
  [EReportTypes.XDocConNoteReport]: {
    code: 'xdoc_connote.xlsx',
    displayValue: 'XDoc Con Note Report',
  },
};
//#endregion Reports

//#region Settings
export const LOAD_OPTION_ORDER_TYPES_MAP: Record<EOrderTypes | EOrderDefaultLoadOption, string> = {
  [EOrderDefaultLoadOption.Anywhere]: 'Everywhere',
  [EOrderTypes.Delivery]: 'Delivery',
  [EOrderTypes.Pickup]: 'Pickup',
  [EOrderTypes.Service]: 'Service',
  [EOrderTypes.PickupDelivery]: 'Pickup-Delivery',
};

export const LOAD_OPTION_CALC_MAP: Record<string, string> = {
  '2': 'Add',
  '1': 'Deduct',
  '-1': 'None',
};
//#endregion Settings

//#region Users
export const USER_PERMISSIONS_MAP: Record<
  EUserPermissions, string
> = {
  [EUserPermissions.Forbidden]: 'Forbidden',
  [EUserPermissions.ReadOnly]: 'Read Only',
  [EUserPermissions.WriteAccess]: 'Write Access',
  [EUserPermissions.FullAccess]: 'Full Access',
};
//#endregion Users

//#region Safety
export const SAFETY_FREQUENCIES_MAP: Readonly<Record<
  ESafetyInspectionFrequency, string
>> = {
  [ESafetyInspectionFrequency.Always]: ESafetyInspectionFrequency[ESafetyInspectionFrequency.Always],
  [ESafetyInspectionFrequency.Daily]: ESafetyInspectionFrequency[ESafetyInspectionFrequency.Daily],
  [ESafetyInspectionFrequency.Weekly]: ESafetyInspectionFrequency[ESafetyInspectionFrequency.Weekly],
  [ESafetyInspectionFrequency.Monthly]: ESafetyInspectionFrequency[ESafetyInspectionFrequency.Monthly],
  [ESafetyInspectionFrequency.Mileage]: ESafetyInspectionFrequency[ESafetyInspectionFrequency.Mileage],
};

export const INSPECTIONS_STATUSES_MAP: Record<ESafetyInspectionStatus, { icon: string, color: EColours }> = {
  [ESafetyInspectionStatus.Pending]: {
    icon: 'schedule',
    color: EColours.Violet,
  },
  [ESafetyInspectionStatus['Critical Fail']]: {
    icon: 'close',
    color: EColours.Red,
  },
  [ESafetyInspectionStatus.Fail]: {
    icon: 'close',
    color: EColours.Red,
  },
  [ESafetyInspectionStatus.Repaired]: {
    icon: 'handyman',
    color: EColours.Orange,
  },
  [ESafetyInspectionStatus.Pass]: {
    icon: 'check',
    color: EColours.Green,
  },
};
//#endregion Safety

//#region Vehicles
export const VEHICLE_CHECK_STATUSES_TOOLTIPS: Record<EVehicleInspectionStatus, string> = {
  [EVehicleInspectionStatus.OK]: 'All inspections passed, no due or missed inspections, no outstanding critical failures',
  [EVehicleInspectionStatus.DueOrMissed]: 'Due or missed inspection',
  [EVehicleInspectionStatus.DueOrMissedAndDriven]: 'Due or missed inspection, and the vehicle has been driven',
  [EVehicleInspectionStatus.CriticalFailure]: 'Outstanding critical failure',
  [EVehicleInspectionStatus.CriticalFailureAndDriven]: 'Outstanding critical failure, and the vehicle has been driven',
  [EVehicleInspectionStatus.Failure]: 'Outstanding non-critical failure',
  [EVehicleInspectionStatus.FailureAndDriven]: 'Outstanding non-critical failure, and the vehicle has been driven',
};

//#endregion Vehicles

//#region Drivers
export const DRIVER_LICENSE_VALIDATION_STATUSES_MAP: Record<ELicenseValidationStatus, IStatusInfo> = {
  [ELicenseValidationStatus.None]: {
    icon: ECustomIcons.Cancel,
    color: ETextClasses.Black,
    text: 'Not defined',
  },
  [ELicenseValidationStatus.NotValidated]: {
    icon: ECustomIcons.HandPalm,
    color: ETextClasses.Blue,
    text: 'Not valid',
  },
  [ELicenseValidationStatus.Validated]: {
    icon: ECustomIcons.Check,
    color: ETextClasses.Green,
    text: 'Valid',
  },
  [ELicenseValidationStatus.Declined]: {
    icon: ECustomIcons.WarningCircle,
    color: ETextClasses.Red,
    text: 'Declined',
  },
};

export const DRIVER_LICENSE_EXPIRATION_STATUSES_MAP: Record<ELicenseExpirationStatus, IStatusInfo> = {
  [ELicenseExpirationStatus.Ok]: {
    icon: ECustomIcons.Check,
    color: ETextClasses.Green,
    text: 'Ok',
  },
  [ELicenseExpirationStatus.AboutToExpire]: {
    icon: ECustomIcons.WarningCircle,
    color: ETextClasses.Orange,
    text: 'Expiring soon',
  },
  [ELicenseExpirationStatus.Expired]: {
    icon: ECustomIcons.WarningCircleFilled,
    color: ETextClasses.Red,
    text: 'Expired',
  },
};
//#endregion Drivers

export const IMPORT_HISTORY_STATUSES_MAP: Record<EImportStatus, IStatusInfo> = {
  [EImportStatus.Process]: {
    icon: ECustomIcons.Play,
    color: ETextClasses.Blue,
    text: 'Progress',
  },
  [EImportStatus.Finished]: {
    icon: ECustomIcons.Check,
    color: ETextClasses.Green,
    text: 'Finished',
  },
  [EImportStatus.Fail]: {
    icon: ECustomIcons.WarningCircleFilled,
    color: ETextClasses.Red,
    text: 'Fail',
  },
  [EImportStatus.Cancelled]: {
    icon: ECustomIcons.Cancel,
    color: ETextClasses.Red,
    text: 'Cancelled',
  },
  [EImportStatus.CancelRequest]: {
    icon: ECustomIcons.Cancel,
    color: ETextClasses.Orange,
    text: 'Cancellation requested',
  },
};

export const INVALID_PHONE_NUMBER = 'Invalid phone number format';
