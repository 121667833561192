import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { interval } from 'rxjs';

import { SharedService } from 'app/services/shared.service';
import { ECustomIcons, EOnboardingSteps } from 'app/consts/enums';
import { ApiService } from 'app/services/api.service';

export interface IOnboardingStatus {
  DriversAreSpecified: boolean;
  VehiclesAreSpecified: boolean;
  CompanyInfoIsSpecified: boolean;
}

export interface IVerificationDetails {
  AccountName: string;
  EmailAddress: string;
  PhoneNumber: string;
  ContactName: string;
  ContactNumber: string;
  ContactPassword: string;
  ConfirmationToken: string;
}

enum EIndicatorClasses {
  Done = 'done',
  InProgress = 'in-progress',
  Unavailable = 'unavailable',
}

@Component({
  selector: 'fuse-app-stepper',
  templateUrl: './onboarding-stepper.component.html',
  styleUrls: ['./onboarding-stepper.component.scss'],
  animations: fuseAnimations,
})
export class OnboardingStepperComponent implements OnInit, AfterViewChecked {
  public isReady = false;

  public steps = EOnboardingSteps;
  public currentStep: EOnboardingSteps = EOnboardingSteps.CompanyInfo;
  public stepsList = Object.values(EOnboardingSteps);
  public onboardingCompleted = false;

  public readonly checkIcon = ECustomIcons.Check;

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    if (!this.sharedService.onboardingHelper.onboardingState) {
      const checker = interval(200).subscribe(() => {
        if (this.sharedService.isReady) {
          checker.unsubscribe();

          this.api.getOnboardingStatus().subscribe((res) => {
            this.sharedService.onboardingHelper.onboardingStatus = res.data;
            this.sharedService.onboardingHelper.onboardingState = {
              [EOnboardingSteps.CompanyInfo]: res.data.CompanyInfoIsSpecified,
              [EOnboardingSteps.Vehicles]: res.data.VehiclesAreSpecified,
              [EOnboardingSteps.Drivers]: res.data.DriversAreSpecified,
            };

            this.isReady = true;
            this.defineCurrentStep();
          });
        }
      });
    } else {
      this.isReady = true;
      this.defineCurrentStep();
    }

    this.sharedService.onboardingHelper.afterFinishAction = this.defineCurrentStep;
  }

  get isApiLoading(): boolean {
    return this.api.loading;
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  private defineCurrentStep = (): void => {
    const unfinishedStep = this.stepsList.find((step) =>
      this.sharedService.onboardingHelper.onboardingState[step] === false
    );
    this.currentStep = unfinishedStep;

    if (!unfinishedStep) {
      this.onboardingCompleted = true;
    }
  }

  public getIndicatorClass = (step: EOnboardingSteps): string => {
    if (this.checkStepIsDone(step)) {
      return EIndicatorClasses.Done;
    } else {
      const previousStep = this.stepsList[this.stepsList.indexOf(step) - 1];
      if (this.checkStepIsDone(previousStep) || !previousStep) {
        return EIndicatorClasses.InProgress;
      } else {
        return EIndicatorClasses.Unavailable;
      }
    }
  }

  private checkStepIsDone = (step: EOnboardingSteps): boolean => {
    return this.sharedService.onboardingHelper.onboardingState[step] === true;
  }

  public finish = (): void => {
    this.router.navigate(['/']);
  }

  public goToStep = (step: EOnboardingSteps): void => {
    this.sharedService.onboardingHelper.onboardingState[step] = false;
    this.defineCurrentStep();
  }
}
